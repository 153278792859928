// Constitution du SidebarContent selon les droits Admin ou User

import React from 'react';

import SidebarContentAdmin from './SidebarContentAdmin';
import SidebarContentUser from './SidebarContentUser';

import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from "jwt-decode";

// Appeler le test sur les droits- Roles pour éviter la gestion en dur dans le code.
const SidebarContent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let decoded;
  token.then((res) => {
    decoded = jwtDecode(res);
  }).catch((err) => console.log(err));

  async function getPermissions() {
    const token = await getAccessTokenSilently();
    return jwtDecode(token).permissions ?? ["NOT CONNECTED"];
  }
  const [permissions, setPermissions] = React.useState(null);

  React.useEffect(() => {
    getPermissions().then(setPermissions);
  }, []);

  // While the permissions are not loaded yet and the user is logged in, show a loading screen
  if (permissions === null && useAuth0().user?.nickname) {
    return <div>Loading...</div>;
  }

  return permissions.includes('create:invoice') ? <SidebarContentAdmin /> : <SidebarContentUser />;
};

export default SidebarContent;
