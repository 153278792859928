import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getContributors,
  getContributorsSuccess,
  deleteContributor,
  deleteContributorSuccess,
} from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getContributorsSaga() {
  const response = yield call(httpClient.instance.get, '/users');
  if (response.status !== undefined && response.status === 200) {
    yield put(getContributorsSuccess(response.data));
  }
}

function* deleteContributorSaga(action) {
  const userId = action.payload;
  const response = yield call(httpClient.instance.delete, `/users/${userId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteContributorSuccess(userId));
  }
}

export default function* contributorsSaga() {
  yield takeLatest(getContributors, handleSagaErrors, getContributorsSaga);
  yield takeLatest(deleteContributor, handleSagaErrors, deleteContributorSaga);
}
