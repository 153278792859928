import React from 'react';
import PropTypes from 'prop-types';
import reactRouterDom, { Route, Redirect } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { jwtDecode } from "jwt-decode";

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, permissionReadAccount, permissionCreateInvoice, permissionCreateSettings, permissionCreateSettingsHigh, ...rest }) => {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let decoded;
  token.then((res) => {
    decoded = jwtDecode(res);
  }).catch((err) => console.log(err));

  async function getPermissions() {
    const token = await getAccessTokenSilently();
    //The permissions are stored in the token
    return jwtDecode(token).permissions ?? ["NOT CONNECTED"];
  }
  const [permissions, setPermissions] = React.useState(null);

  React.useEffect(() => {
    getPermissions().then(setPermissions);
  }, []);

  // While the permissions are not loaded yet and the user is logged in, show a loading screen
  if (permissions === null && useAuth0().user?.nickname) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // Checking for all the permissions one by one
        if (isAuthProtected && !permissions) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        }
        if (permissionReadAccount && !permissions.includes('read:account')) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        }
        if (permissionCreateInvoice && !permissions.includes('create:invoice')) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        }
        if (permissionCreateSettings && !permissions.includes('create:settings')) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        }
        if (permissionCreateSettingsHigh && !permissions.includes('create:settings-high')) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        }
        
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
