import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getLineTypes,
  getLineTypesSuccess,
  deleteLineType,
  deleteLineTypeSuccess,
  addLineType,
  addLineTypeSuccess,
} from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getLineTypesSaga() {
  const response = yield call(httpClient.instance.get, '/typelignes');
  if (response.status !== undefined && response.status === 200) {
    yield put(getLineTypesSuccess(response.data));
  }
}

function* addLineTypeSaga(action) {
  const data = action.payload;
  try {
    const response = yield call(httpClient.instance.post, `/typelignes`, {
      dossier: data.dossier,
      typeLigne: data.lineType,
    });
    if (response.status !== undefined && response.status === 200) {
      yield put(addLineTypeSuccess(response.data));
    }
  } catch (err) {
    console.error('ADD_LINE_TYPE_ERROR', err);
  }
}

function* deleteLineTypeSaga(action) {
  const { dossier, lineType } = action.payload;
  const response = yield call(httpClient.instance.delete, `/typelignes/${dossier}/${lineType}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteLineTypeSuccess(action.payload));
  }
}

export default function* lineTypesSaga() {
  yield takeLatest(getLineTypes, handleSagaErrors, getLineTypesSaga);
  yield takeLatest(addLineType, handleSagaErrors, addLineTypeSaga);
  yield takeLatest(deleteLineType, handleSagaErrors, deleteLineTypeSaga);
}
