import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import { getProductionLines, getProductionLinesSuccess } from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getProductionLinesSaga() {
  const response = yield call(httpClient.instance.get, '/lignesprod');
  if (response.status !== undefined && response.status === 200) {
    yield put(getProductionLinesSuccess(response.data));
  }
}

export default function* productionLinesSaga() {
  yield takeLatest(getProductionLines, handleSagaErrors, getProductionLinesSaga);
}
