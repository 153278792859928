import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import { getOperators, getOperatorsSuccess, deleteOperator, deleteOperatorSuccess } from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getOperatorsSaga() {
  const response = yield call(httpClient.instance.get, '/operateurs');
  if (response.status !== undefined && response.status === 200) {
    yield put(getOperatorsSuccess(response.data));
  }
}

function* deleteOperatorSaga(action) {
  const operatorId = action.payload;
  const response = yield call(httpClient.instance.delete, `/operateurs/${operatorId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteOperatorSuccess(operatorId));
  }
}

export default function* OperatorsSaga() {
  yield takeLatest(getOperators, handleSagaErrors, getOperatorsSaga);
  yield takeLatest(deleteOperator, handleSagaErrors, deleteOperatorSaga);
}
