import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import { getInvoices, getInvoicesSuccess, deleteInvoice, deleteInvoiceSuccess } from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getInvoicesSaga() {
  const response = yield call(httpClient.instance.get, '/payments');
  if (response.status !== undefined && response.status === 200) {
    yield put(getInvoicesSuccess(response.data));
  }
}

function* deleteInvoiceSaga(action) {
  const invoiceId = action.payload;
  const response = yield call(httpClient.instance.delete, `/payments/${invoiceId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteInvoiceSuccess(invoiceId));
  }
}

export default function* invoicesSaga() {
  yield takeLatest(getInvoices, handleSagaErrors, getInvoicesSaga);
  yield takeLatest(deleteInvoice, handleSagaErrors, deleteInvoiceSaga);
}
