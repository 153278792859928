import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getErrorMessageConfigs,
  getErrorMessageConfigsSuccess,
  deleteErrorMessageConfig,
  deleteErrorMessageConfigSuccess,
} from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getErrorMessageConfigsSaga() {
  const response = yield call(httpClient.instance.get, '/error-message-configs');
  if (response.status !== undefined && response.status === 200) {
    yield put(getErrorMessageConfigsSuccess(response.data));
  }
}

function* deleteErrorMessageConfigSaga(action) {
  const id = action.payload;
  const response = yield call(httpClient.instance.delete, `/error-message-configs/${id}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteErrorMessageConfigSuccess(id));
  }
}

export default function* ErrorMessageConfigsSaga() {
  yield takeLatest(getErrorMessageConfigs, handleSagaErrors, getErrorMessageConfigsSaga);
  yield takeLatest(deleteErrorMessageConfig, handleSagaErrors, deleteErrorMessageConfigSaga);
}
